import React, { useState, useEffect } from 'react'
import Layout from "../components/layout"
import Project from '../components/project'
import SEO from "../components/seo"
import "./index.css"
import './skills.css'

const IndexPage = (props) => {

  const [contentDisposition, setContentDisposition] = useState(null)
  const [category, setCategory] = useState('all')

  useEffect(() => {
    if (contentDisposition !== null) {
      const main = document.getElementsByTagName('main')[0]
      main.dispatchEvent(new Event('resize', { 'bubbles': true }))
    }
    if (category !== 'all') {
      window.location.hash = `#${category}`
    } else if (contentDisposition !== null) {
      window.history.pushState(null, null, ' ')
    }
  }, [category, contentDisposition])

  useEffect(() => {
    if (props.location.hash.length > 0 ) {
      console.log('hash exists',  props.location.hash.substring(1))
      setCategory(props.location.hash.substring(1))
    } else {
      setCategory('all')
    }
  }, [props.location.hash])

  useEffect(() => {
    const savedValue = window.localStorage.getItem('contentDisposition');
    setContentDisposition(savedValue ? savedValue : 'smallCard');
  }, [])

  const defaultButtonColor = '#337ab7'
  const activeButtonColor = '#31b0d5'  

  const changeContentDisposition = (disposition) => {
    window.localStorage.setItem('contentDisposition', disposition)
    setContentDisposition(disposition)
  }

  return (
  contentDisposition &&
  <Layout pageTitle="micpob.com" >
    <SEO title="Home" />
    <h1 id="homepage_h1">Projects</h1>
    <br></br>
    <div id="show_content_options_container" style={{ display: 'flex', marginBottom: '20px', alignItems: 'center' }}>
      <div id="categories_buttons_container" >
        <button id="all_category_button" style={{ backgroundColor: category === 'all' ? activeButtonColor : defaultButtonColor }} onClick={ () => setCategory('all') }>All</button>
        <button id="web_apps_category_button" style={{ backgroundColor: category === 'web_apps' ? activeButtonColor : defaultButtonColor }} onClick={ () => {setCategory('web_apps')/* ; document.location.hash = 'web_apps' */ } }>Web</button>
        <button id="extensions_category_button" style={{ backgroundColor: category === 'extensions' ? activeButtonColor : defaultButtonColor }} onClick={ () => setCategory('extensions') }>Extensions</button>
        <button id="android_category_button" style={{ backgroundColor: category === 'android' ? activeButtonColor : defaultButtonColor }} onClick={ () => setCategory('android') }>Android</button>
        <button id="games_category_button" style={{ backgroundColor: category === 'games' ? activeButtonColor : defaultButtonColor }} onClick={ () => setCategory('games') }>Games</button>
        {/* <button id="vba_apps_category_button" style={{ backgroundColor: category === 'vba_apps' ? activeButtonColor : defaultButtonColor }} onClick={ () => setCategory('vba_apps') }>VBA</button> */}
        {/* <button id="data_category_button" onClick={ () => setCategory('data') }>Data</button> */}
      </div>
      <div id="content_disposition_buttons_container" /* style={{ marginLeft: 'auto' }} */>
        <button aria-label="Show projects in grid mode" id="grid" onClick={ () => changeContentDisposition('smallCard') } ></button>
        <button aria-label="Show projects in list mode" id="list" onClick={ () => changeContentDisposition('listing') } ></button>
      </div>
    </div>
    <div id="projects_container">
      { (category === 'all' || category === 'web_apps' || category === 'games') && <Project 
          contentDisposition={contentDisposition}
          title="Doremify" 
          description="A gamified single page application made to train notes finding on various instruments."
          categories="web_apps games"
          stack="HTML, CSS, JavaScript, PHP, SQL"
          details="projects/doremify"
          live="https://www.doremify.com" 
          code="https://github.com/micpob/Doremify" 
          private>        
        </Project>
      }   
      { (category === 'all' || category === 'web_apps') && <Project 
          contentDisposition={contentDisposition}
          title="Bloglist App" 
          description="React app that shows a collaborative list of interesting blogs about software development."
          categories="web_apps"
          stack="React, CSS, NodeJs, MongoDB"
          details="projects/bloglist-app"
          live="https://www.micpob.com/bloglist-app/" 
          code="https://github.com/micpob/Bloglist-app"
          private> 
        </Project>
      }
      { (category === 'all' || category === 'web_apps') && <Project 
          contentDisposition={contentDisposition}
          title="WakWak" 
          description="A special kind of countdown timer that needs your attention all the time!"
          categories="web_apps"
          stack="React"
          details="projects/wakwak"
          live="https://www.micpob.com/wakwak/" 
          code="https://github.com/micpob/Wakwak" 
          private>
        </Project>
      }
      { (category === 'all' || category === 'web_apps') && <Project 
          contentDisposition={contentDisposition}
          title="Asociación Comerciantes" 
          description="Website of a business owners association, made as a proof of concept for a course."
          categories="web_apps"
          stack="HTML, CSS, JavaScript, PHP, SQL"
          details="projects/asociacion-comerciantes"
          live="https://www.micpob.com/asociacion_comerciantes/" 
          code="https://github.com/micpob/Asociacion-Comerciantes" 
          private>
        </Project>
      }
      { (category === 'all' || category === 'web_apps' || category === 'games') && <Project 
          contentDisposition={contentDisposition}
          title="Zenter" 
          description="Online game where you have to put a circle in the perfect center of a green canvas."
          categories="games web_apps"
          stack="HTML, CSS, JavaScript, NodeJs, MongoDB"
          details="projects/zenter"
          live="https://www.micpob.com/zenter/" 
          code="https://github.com/micpob/Zenter" 
          private>  
        </Project>
      }
      { (category === 'all' || category === 'web_apps') && <Project 
          contentDisposition={contentDisposition}
          title="Beautiful Words" 
          description="A visually powerful combination of beautiful pictures and interesting quotes."
          categories="web_apps"
          stack="HTML, CSS, JavaScript, NodeJs, Puppeteer"
          details="projects/beautiful-words"
          live="https://www.micpob.com/beautiful_words/" 
          code="https://github.com/micpob/Beautiful-Words"
          private> 
        </Project>
      }
      { (category === 'all' || category === 'web_apps') && <Project 
          contentDisposition={contentDisposition}
          title="Portfolio" 
          description="My portfolio page, built to show my projects and skills. You are actually here now!"
          categories="web_apps"
          stack="Gatsby, React, CSS, NodeJs"
          details="projects/portfolio"
          live="https://www.micpob.com" 
          code="https://github.com/micpob/Portfolio" 
          private>  
        </Project>
      }
      { (category === 'all' || category === 'extensions') && <Project 
          contentDisposition={contentDisposition}
          title="Vinylizr" 
          description="This Chrome extension adds a vinyl sound background to YouTube videos."
          categories="extensions"
          stack="HTML, CSS, JavaScript"
          details="projects/vinylizr"
          live="https://chrome.google.com/webstore/detail/vinylizr-for-youtube/menanhkamjnihglgnockbffnjnngolfn" 
          code="https://github.com/micpob/Vinylizr" 
          private>
        </Project>
      }
      { (category === 'all' || category === 'extensions') && <Project 
          contentDisposition={contentDisposition}
          title="Random Bookmark Machine" 
          description="A highly customizable Chrome extension that opens random URLs from your bookmarks."
          categories="extensions"
          stack="HTML, CSS, JavaScript"
          details="projects/random-bookmark-machine"
          live="https://chrome.google.com/webstore/detail/random-bookmark-machine/jpofhhlmbncfdodogngdidhkecjigaab"
          code="https://github.com/micpob/Random-Bookmark-Machine"
          > 
        </Project>
      }
      { (category === 'all' || category === 'extensions') && <Project 
          contentDisposition={contentDisposition}
          title="Simple Counter Button" 
          description="A Chrome extension that adds a counter controlled by a button to your toolbar."
          categories="extensions"
          stack="HTML, CSS, JavaScript"
          details="projects/simple-counter-button"
          live="https://chrome.google.com/webstore/detail/simple-counter-button/ganimlbiphepcnkneheoamhohhmjpohp"
          code="https://github.com/micpob/Simple-Counter-Button"> 
        </Project>
      }
      { (category === 'all' || category === 'extensions') && <Project 
          contentDisposition={contentDisposition}
          title="Copy on Select" 
          description="This Chrome extension automatically copies everything you select to the clipboard."
          categories="extensions"
          stack="HTML, CSS, JavaScript"
          details="projects/copy-on-select"
          live="https://chrome.google.com/webstore/detail/copy-on-select/kdfngfkkopeoejecmfejlcpblohnbael" 
          code="https://github.com/micpob/Copy-On-Select" 
          private>
        </Project>
      }
      { (category === 'all' || category === 'android') && <Project 
          contentDisposition={contentDisposition}
          title="Stack Explorer" 
          description="Android app to randomly explore questions and answers on the Stack Exchange network."
          categories="android"
          stack="React Native"
          details="projects/stack-explorer"
          /* live="https://play.google.com/store/apps/details?id=com.github.micpob.stackexplorer" */ 
          code="https://github.com/micpob/Stack-Explorer" 
          >        
        </Project>
      }
      { (category === 'all' || category === 'games' || category === 'android') && <Project 
          contentDisposition={contentDisposition}
          title="Pop Them All" 
          description="A colorful and easy-going game for Android. See those balloons? You have to pop them all!"
          categories="games android"
          stack="MIT App Inventor"
          details="projects/pop-them-all"
          /* live="https://play.google.com/store/apps/details?id=com.bombolo_app.Pop_Them_All" */
          code="https://github.com/micpob/Pop-them-all-releases" 
          > 
        </Project>
      }
      { (category === 'all' || category === 'android') && <Project 
          contentDisposition={contentDisposition}
          title="Bombolo App" 
          description="Android app made as a tribute to the Italian comedian Franco Lechner, AKA Bombolo."
          categories="android"
          stack="MIT App Inventor"
          details="projects/bombolo-app"
          code="https://github.com/micpob/Bombolo-App" 
          > 
        </Project>
      }
      {/* { (category === 'all' || category === 'vba_apps') && <Project 
          contentDisposition={contentDisposition}
          title="VBA Code Obfuscator" 
          description="A VBA app that parses Excel files and makes VBA code unintelligible to the human eye."
          categories="vba_apps"
          stack="VBA (Visual Basic for Applications)"
          details="projects/vba-code-obfuscator"
          code="https://github.com/micpob/VBA-Code-Obfuscator"
          private> 
        </Project>
      }
      { (category === 'all' || category === 'vba_apps') && <Project 
          contentDisposition={contentDisposition}
          title="Video QA App" 
          description="This application was created to improve the work of a team of audiovisual QA analysts."
          categories="vba_apps"
          stack="VBA (Visual Basic for Applications)"
          details="projects/video-qa-app"
          code="https://github.com/micpob/Video-QA-App"
          private> 
        </Project>
      } */}
    </div>
  </Layout>
  )
}
export default IndexPage
