import { Link } from "gatsby"
import React from "react"
import ProjectThumbnail from './projectThumbnailImage'
import './project.css'

const privateRepoAlert = () => {
  alert(`Sorry, but this Github repo is currently private. \n\nPlease contact me if you want to have access to it.`)
}

const Project = ( props ) => (
 // <div className={ `project-container ${props.categories} ${typeof window !== 'undefined' && window.innerWidth > 885 ? "smallCard" : ""}` }>
  <div className={ `project-container ${props.categories} ${props.contentDisposition}` }>
    <div className="info-container">
      
      <div className="project-text"> 
        <h3>{ props.title }</h3>     
          <p style={{ textAlign: `justify` }}>{ props.description }</p>
        </div> 
      <div className="stack-list" style={{ marginTop: `auto` }}>
        {
          props.stack &&
          <>
            <strong>Made with: </strong>
            <p style={{ marginTop: `5px` }}>{ props.stack }</p>
          </>
        } 
      </div>
      <div className="project-buttons"> 
           
        { props.details &&
          <Link to={ props.details }><button title="Detailed presentation">Info</button></Link>
        }
        { props.live &&
          <a href={ props.live } target="_blank" rel="noopener noreferrer"><button title="Live site">Live</button></a>
        }
        { props.code &&
          props.private ?
            <button title="Github repo" onClick={ privateRepoAlert }>Code</button> :
            <a href={ props.code } target="_blank" rel="noopener noreferrer"><button title="Github repo">Code</button></a>
        }
      </div>
    </div>  
      <a className="image-link" href={ props.live || props.details } target={ props.live ? "_blank": "" } rel="noopener noreferrer">  
        <ProjectThumbnail alt={props.title} filename={props.title} linkToLive={props.live} />
      </a>  
  </div>  
)

export default Project
